import React from 'react'
import styles from './Footer.module.scss'
import { Container } from 'react-bootstrap'

// #TODO add commit hash
// const commitHash = process.env.REACT_APP_VERSION || 'dev'

export default function Footer(props) {
  return (
    <Container fluid style={{ backgroundColor: 'var(--dark)' }}>
      <Container className={styles.footer}>
            <p className="Xsmall">
              fork from <a href='https://rawgraphs.io/' target='_blank' rel="noreferrer">rawgraphs.io</a>
            </p>
      </Container>
    </Container>
  )
}
