import React from 'react'
import styles from './Header.module.scss'
import { Navbar, Nav } from 'react-bootstrap'

export default function Header({ menuItems }) {
  return (
    <Navbar bg="white" expand="lg" sticky="top" className={styles.navbar}>
      <Navbar.Brand href="/">
        <b className="text-primary">BioGraph</b>
        <span>Tools</span>{' '}
      </Navbar.Brand>
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="ml-auto">
          {menuItems.map((d, i) => {
            return (
              <Nav.Link key={'item' + i} href={d.href} id={d.id} target='_blank'>
                {d.label}
              </Nav.Link>
            )
          })}
          <a
            role="button"
            href="//cloud.iotabiome.com/#/user/account/overview"
            target='_blank'
            rel="noopener noreferrer"
            className="btn btn-sm btn-primary ml-2 d-flex flex-column align-items-center justify-content-center"
          >
            会员中心
          </a>
        </Nav>
      </Navbar.Collapse>
    </Navbar>
  )
}
