const headerItems = [
  {
    label: '微次元',
    slug: '微次元',
    href: '//iotabiome.com/',
    id: 'iotabiome'
  },
  {
    label: '组学分析',
    slug: '组学分析',
    href: '//iotabiome.com/?page_id=267',
    id: 'genome'
  },
  {
    label: '源基因',
    slug: '源基因',
    href: '//yoogene.com/',
    id: 'yoogene'
  },
  {
    label: '父系溯源',
    slug: '父系溯源',
    href: '//yoogene.com/goods/goodsdetail/goods_id/3',
    id: 'url'
  },
  {
    label: '组合溯源',
    slug: '组合溯源',
    href: '//yoogene.com/goods/goodsdetail/goods_id/9',
    id: 'project'
  },
  {
    label: 'Mind',
    slug: 'Mind',
    href: '//idea.iotabiome.com',
    id: 'project'
  },
]

export default headerItems
